import constants from '../constants'
import { Form } from '../components/form'
import { Validation } from '../util/validation'

class ChangePassword {
  init(form) {
    form.addEventListener('submit', event => {
      document.body.focus()
      let hasError = false
      const passwordField = form.querySelector('#' + constants.signupDetails.fields.password)
      const confirmPasswordField = form.querySelector('#' + constants.signupDetails.fields.confirmPassword)
      const formUtil = new Form()
      const validation = new Validation()

      formUtil.clearAllFieldErrors(form)

      if (validation.validatePassword(passwordField)) {
        hasError = true
      }

      if (validation.validateConfrimPassword(passwordField, confirmPasswordField)) {
        hasError = true
      }

      if (hasError) {
        event.preventDefault()
      }
    })
  }
}

export { ChangePassword }

import constants from '../constants'
import { Form } from '../components/form'

class ForgotPasswordConfirm {
  init(form) {
    form.addEventListener('submit', event => {
      document.body.focus()
      let hasError = true
      const codeField = form.querySelector('#' + constants.forgotPasswordConfirm.fields.code)
      const code = codeField.value
      const formUtil = new Form()

      formUtil.clearAllFieldErrors(form)

      if (code.trim().length < 1) {
        formUtil.showFieldError(codeField, constants.errors.emptyCode)
      }
      else if (!/^\d{6}$/gm.test(code.trim())) {
        formUtil.showFieldError(codeField, constants.errors.invalidCode)
      }
      else {
        hasError = false
      }

      if (hasError) {
        event.preventDefault()
      }
    })
  }
}

export { ForgotPasswordConfirm }

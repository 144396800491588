import constants from '../constants'
import { Form } from '../components/form'
import { validateEmail } from '../util'

class SignIn {
  init(form) {
    form.addEventListener('submit', event => {
      event.preventDefault()

      let hasError = false
      const userField = form.querySelector('#' + constants.signin.fields.user)
      const passwordField = form.querySelector('#' + constants.signin.fields.password)
      const user = userField.value
      const formUtil = new Form()

      formUtil.clearAllFieldErrors(form)

      if (user.trim().length < 1) {
        hasError = true
        formUtil.showFieldError(userField, constants.errors.emptyUser)
      }
      else if (!validateEmail(user)) {
        hasError = true
        formUtil.showFieldError(userField, constants.errors.invalidEmail)
      }

      if (passwordField.value.trim().length < 1) {
        hasError = true
        formUtil.showFieldError(passwordField, constants.errors.emptyPassword)
      }

      if (!hasError) {
        formUtil.postRequest().post('/api/user/auth', {
          login: user.trim(),
          password: passwordField.value.trim()
        })
          .then(res => {
            if (res.status === 200) {
              if (res.data.success == 'true') {
                window.location = res.data.redirect_uri
              }
              else {
                formUtil.showCommonError(constants.errors.invalidUserCredentials)
              }
            }
            else {
              formUtil.showCommonError(constants.errors.error)
            }
          })
          .catch(error => {
            formUtil.showCommonError(constants.errors.error)
          })
      }

      return false
    })
  }
}

export { SignIn }

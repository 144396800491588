import constants from '../constants'
import { Form } from '../components/form'

class Validation {
  validatePassword(passwordField) {
    let hasError = false
    const formUtil = new Form()
    const passwordValue = passwordField.value.trim()

    if (passwordValue.length < 8) {
      hasError = true
      formUtil.showFieldError(passwordField, constants.errors.tooShortPassword)
    }
    else if (passwordValue.length > 30) {
      hasError = true
      formUtil.showFieldError(passwordField, constants.errors.tooLongPassword)
    }
    else if (!/[a-z]/gm.test(passwordValue)) {
      hasError = true
      formUtil.showFieldError(passwordField, constants.errors.noLowercasePassword)
    }
    else if (!/[A-Z]/gm.test(passwordValue)) {
      hasError = true
      formUtil.showFieldError(passwordField, constants.errors.noUppercasePassword)
    }
    else if (!/\d/gm.test(passwordValue)) {
      hasError = true
      formUtil.showFieldError(passwordField, constants.errors.noNumbersPassword)
    }
    else if (!/[\W_]/gm.test(passwordValue)) {
      hasError = true
      formUtil.showFieldError(passwordField, constants.errors.noSpecialCharactersPassword)
    }

    return hasError
  }

  validateConfrimPassword(passwordField, confirmPasswordField) {
    let hasError = false
    const formUtil = new Form()
    const passwordValue = passwordField.value.trim()

    if (confirmPasswordField.value.trim().length < 1) {
      hasError = true
      formUtil.showFieldError(confirmPasswordField, constants.errors.emptyConfirmPassword)
    }
    else if (passwordValue != confirmPasswordField.value.trim()) {
      hasError = true
      formUtil.showFieldError(confirmPasswordField, constants.errors.mismatchConfirmPassword)
    }

    return hasError
  }
}

export { Validation }

export default {
  signin: {
    form: 'frmLogin',
    fields: {
      user: 'txtUser',
      password: 'txtPass'
    }
  },
  signup: {
    form: 'frmCreateAccount',
    fields: {
      user: 'txtUser'
    }
  },
  signupConfirm: {
    form: 'frmVerifyAccount',
    fields: {
      code: 'txtCode'
    }
  },
  signupDetails: {
    form: 'frmAccountDetails',
    fields: {
      firstName: 'txtFirstName',
      lastName: 'txtLastName',
      mobile: 'txtMobile',
      password: 'txtPassword',
      confirmPassword: 'txtConfirmPassword',
      terms: 'chkTerms'
    }
  },
  forgotPassword: {
    form: 'frmForgotPassword',
    fields: {
      user: 'txtUser'
    }
  },
  forgotPasswordConfirm: {
    form: 'frmConfirmAccount',
    fields: {
      code: 'txtCode'
    }
  },
  changePassword: {
    form: 'frmResetPassword',
    fields: {
      password: 'txtPassword',
      confirmPassword: 'txtConfirmPassword'
    }
  },
  errors: {
    error: 'Something went wrong. Please try again later.',
    invalidUserCredentials: 'Enter a valid username and password.',
    emptyUser: 'Email address should be entered.',
    invalidEmail: 'Enter a valid email address.',
    emptyCode: 'Confirmation code should be entered.',
    invalidCode: 'Enter a six-digit confirmation code.',
    emptyFirstName: 'First name should be entered.',
    emptyLastName: 'Last name should be entered.',
    emptyMobile: 'Mobile number should be entered.',
    invalidMobile: 'Enter a valid mobile number.',
    emptyPassword: 'Password should be entered.',
    tooShortPassword: 'Password is too short (minimum 8 characters).',
    tooLongPassword: 'Password is too long (maximum 30 characters).',
    noLowercasePassword: 'Password should contain lowercase letters.',
    noUppercasePassword: 'Password should contain uppercase letters.',
    noNumbersPassword: 'Password should contain numbers.',
    noSpecialCharactersPassword: 'Password should contain special symbols.',
    emptyConfirmPassword: 'Confirm password should be entered.',
    mismatchConfirmPassword: 'Password &amp; Confirm password should be same.',
    agreeTerms: 'You should agree to the Terms and Conditions.'
  }
}

import axios from 'axios';

const httpClient = axios.create({
  baseURL: 'https://' + location.hostname
})

const _get = httpClient.get
const _post = httpClient.post

class Form {
  clearAllFieldErrors(form) {
    const hasError = form.querySelectorAll('.has-error')
    const helpBlock = form.querySelectorAll('.help-block')

    hasError.forEach(node => {
      node.classList.remove('has-error')
    })

    helpBlock.forEach(node => {
      node.innerHTML = ''
    })
  }

  showCommonError(errorMessage) {
    document.querySelector('.commonError').innerHTML = errorMessage
    document.querySelector('.commonError').classList.remove('d-none')
  }

  showFieldError(field, errorMessage) {
    document.querySelector('.commonError').classList.add('d-none')
    const fieldRow = field.parentElement
    fieldRow.classList.add('has-error')
    fieldRow.querySelector('.help-block').innerHTML = errorMessage
  }

  sendRequest(option) {
    return httpClient
      .request(options)
      .then(response => {
        let result = response.data

        // Return response data immediately if raw data is expected
        if (!!options.raw) {
          return result
        }

        if (result.success !== undefined) {
          result = result.success
        }

        return result
      })
      .catch(error => {
        throw error
      })
  }

  getRequest() {

  }

  postRequest() {
    httpClient.post = function (url, query = {}, config = {}) {
      return _post(url, { ...query, ...config })
    }.bind(httpClient)

    return httpClient
  }
}

export { Form }

import "../scss/main.scss"

import constants from './constants'
import { SignIn } from './modules/sign-in'
import { SignUp } from './modules/sign-up'
import { SignUpConfirm } from './modules/sign-up-confirm'
import { SignUpDetails } from './modules/sign-up-details'
import { ForgotPassword } from './modules/forgot-password'
import { ForgotPasswordConfirm } from './modules/forgot-password-confirm'
import { ChangePassword } from './modules/change-password'

const signInForm = document.getElementById(constants.signin.form)
const signUpForm = document.getElementById(constants.signup.form)
const signUpConfirmForm = document.getElementById(constants.signupConfirm.form)
const signUpDetailsForm = document.getElementById(constants.signupDetails.form)
const forgotPasswordForm = document.getElementById(constants.forgotPassword.form)
const forgotPasswordConfirmForm = document.getElementById(constants.forgotPasswordConfirm.form)
const changePasswordForm = document.getElementById(constants.changePassword.form)

if (signInForm) {
  const signin = new SignIn()
  signin.init(signInForm)
}
else if (signUpForm) {
  const signup = new SignUp()
  signup.init(signUpForm)
}
else if (signUpConfirmForm) {
  const signupConfirm = new SignUpConfirm()
  signupConfirm.init(signUpConfirmForm)
}
else if (signUpDetailsForm) {
  const signupDetails = new SignUpDetails()
  signupDetails.init(signUpDetailsForm)
}
else if (forgotPasswordForm) {
  const forgotPassword = new ForgotPassword()
  forgotPassword.init(forgotPasswordForm)
}
else if (forgotPasswordConfirmForm) {
  const forgotPasswordConfirm = new ForgotPasswordConfirm()
  forgotPasswordConfirm.init(forgotPasswordConfirmForm)
}
else if (changePasswordForm) {
  const changePassword = new ChangePassword()
  changePassword.init(changePasswordForm)
}

import constants from '../constants'
import { Form } from '../components/form'
import { Validation } from '../util/validation'

class SignUpDetails {
  init(form) {
    const mobileField = form.querySelector('#' + constants.signupDetails.fields.mobile)

    mobileField.addEventListener('input', event => {
      event.target.value = event.target.value.replace(/[^0-9]/g, '')
    })

    form.addEventListener('submit', event => {
      document.body.focus()
      let hasError = false
      const firstNameField = form.querySelector('#' + constants.signupDetails.fields.firstName)
      const lastNameField = form.querySelector('#' + constants.signupDetails.fields.lastName)
      const passwordField = form.querySelector('#' + constants.signupDetails.fields.password)
      const confirmPasswordField = form.querySelector('#' + constants.signupDetails.fields.confirmPassword)
      const termsField = form.querySelector('#' + constants.signupDetails.fields.terms)
      const formUtil = new Form()
      const validation = new Validation()

      formUtil.clearAllFieldErrors(form)

      if (firstNameField.value.trim().length < 1) {
        hasError = true
        formUtil.showFieldError(firstNameField, constants.errors.emptyFirstName)
      }

      if (lastNameField.value.trim().length < 1) {
        hasError = true
        formUtil.showFieldError(lastNameField, constants.errors.emptyLastName)
      }

      if (mobileField.value.trim().length < 1) {
        hasError = true
        formUtil.showFieldError(lastNameField, constants.errors.emptyMobile)
      }

      if (mobileField.value.trim().length < 10 || mobileField.value.trim()[0] < 6) {
        hasError = true
        formUtil.showFieldError(lastNameField, constants.errors.invalidMobile)
      }

      if (validation.validatePassword(passwordField)) {
        hasError = true
      }

      if (validation.validateConfrimPassword(passwordField, confirmPasswordField)) {
        hasError = true
      }

      if (!termsField.checked) {
        hasError = true
        formUtil.showFieldError(termsField, constants.errors.agreeTerms)
      }

      if (hasError) {
        event.preventDefault()
      }
    })
  }
}

export { SignUpDetails }

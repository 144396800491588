import constants from '../constants'
import { Form } from '../components/form'
import { validateEmail } from '../util'

class ForgotPassword {
  init(form) {
    form.addEventListener('submit', event => {
      let hasError = true
      const userField = form.querySelector('#' + constants.forgotPassword.fields.user)
      const user = userField.value
      const formUtil = new Form()

      formUtil.clearAllFieldErrors(form)

      if (user.trim().length < 1) {
        formUtil.showFieldError(userField, constants.errors.emptyUser)
      }
      else if (!validateEmail(user)) {
        formUtil.showFieldError(userField, constants.errors.invalidEmail)
      }
      else {
        hasError = false
      }

      if (hasError) {
        event.preventDefault()
      }
    })
  }
}

export { ForgotPassword }
